import axios from 'axios'
import router from '@/router'

export default {
  state: {
    clientes: [],
    cliente: {},
  },
  mutations: {
    setClientes(state, payload) {
      state.clientes = payload
    },
    setCliente(state, payload) {
      state.cliente = payload
    },
  },
  actions: {
    setClientes(context, payload) {
      context.commit('setClientes', payload)
    },
    setCliente(context, payload) {
      context.commit('setCliente', payload)
    },
    loadClientes(context) {
      axios(`clientes`).then((resp) => {
        if (resp.status < 400) {
          context.commit('setClientes', resp.data)
        }
      })
    },
    loadClienteByHash(context, payload) {
      axios(`clientes/hash?hash=${payload.hash}`).then((resp) => {
        if (resp.status < 400) {
          if (!resp.data.id && payload.notFoundTo404) {
            router.push({ name: 'NotFound' })
          } else {
            context.commit('setCliente', resp.data);
          }
        } else {
          const error = resp.data.error
          if (typeof payload.callback == 'function') {
            payload.callback(error)
          }
        }
      })
    },
    loadClienteByHashForConfirmation(context, payload) {
      axios(`clientes/confirm?hash=${payload.hash}`).then((resp) => {
        if (resp.status < 400) {
          if (!resp.data.id && payload.notFoundTo404) {
            router.push({ name: 'NotFound' })
          } else {
            context.commit('setCliente', resp.data);
          }
        } else {
          const error = resp.data.error
          if (typeof payload.callback == 'function') {
            payload.callback(error)
          }
        }
      })
    },
    loadClienteById(context, payload) {
      axios(`clientes/${payload.id}`).then((resp) => {
        if (resp.status < 400) {
          // type está vindo como booleano, mas o esperado é um inteiro
          resp.data.type = resp.data.type ? 1 : resp.data.type;
          context.commit('setCliente', resp.data);
          if (!resp.data.id && payload.notFoundTo404) {
            router.push({ name: 'NotFound' })
          }
        }
      })
    },
    saveCliente(context, payload) {
      if (typeof payload.picture == 'string') {
        delete payload.picture
      }
      
      axios({
        method: payload.id ? 'put' : 'post',
        url: payload.id ? `clientes/${payload.id}` : 'clientes',
        data: payload,
      })
        .then((resp) => {
          if (resp.status < 400) {
            if (payload.picture) {
              let picture = new FormData()
              picture.append('picture', payload.picture[0])
              axios({
                method: 'put',
                url: `clientes/${resp.data.id}`,
                data: picture,
                headers: { 'Content-Type': 'multipart/form-data' },
              }).then((resp) => {
                if (resp.status < 400) {
                  context.dispatch('loadClientes')
                }
              })
            }
            router.push({ name: 'Clientes' })
            context.dispatch('showSuccessSnack', 'Cliente salvo com sucesso!')
            context.dispatch('loadClientes')
          }
        })
    },
    deleteCliente(context, payload) {
      axios({
        method: 'delete',
        url: `clientes/${payload.id}`,
      })
        .then((resp) => {
          if (resp.status < 400) {
            context.dispatch('loadClientes')
            context.dispatch('showSuccessSnack', 'Cliente excluído com sucesso!')
            router.push({ name: 'Clientes' })
          }
        })
    },
  },
  getters: {
    getClientes(state) {
      return state.clientes
    },
    getCliente(state) {
      return state.cliente
    },
  },
}