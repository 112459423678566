import axios from 'axios'
import store from '../../store/index'

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = '/api/'
} else {
    axios.defaults.baseURL = 'http://localhost:3333/api/'
}

const success = res => res
const error = err => {
    let retorno = {
        data: err.response.data || [],
        status: err.response.status || 500,
        headers: {
            "content-length": err.response.headers["content-length"] || 0,
            "content-type": err.response.headers["content-type"] || 'application/json',
        }
    }
    switch (err.response.status) {
        case 400:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", `Erro ${err.response.data.error ? err.response.data.error : err.response.data}`);
            return retorno;
        case 401:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", "Não Autorizado");
            return retorno;
        case 307:
            store.dispatch('resetSnack')
            store.commit('destroyUser')
            store.dispatch("showErrorSnack", "Sua Sessão Expirou");
            // window.location = '/'
            return retorno;
        case 405:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", `Sem Permissão para usar o Recurso ${err.config.url}`);
            return retorno;
        case 500:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", "Erro no Servidor");
            console.log(JSON.stringify(err.response.data));
            return retorno;
        default:
            return Promise.reject(err)
    }
}

axios.interceptors.response.use(success, error)

export default axios