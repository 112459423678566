// import Vue from 'vue'
// import Vuex from 'vuex'
import { createStore } from 'vuex'
import axios from 'axios'
import router from '../router/index'
import snackbar from './modules/snackbar'
import grupo from './modules/grupos'
import menu from './modules/menu'
import clientes from './modules/clientes'
import moradores from './modules/moradores'
import usuario from './modules/usuario'
import utils from './modules/utils'

// Vue.use(Vuex)

export default createStore({
    state: {
        user: {},
        token: null,
        logged: false,
    },
    mutations: {
        setPermissoes(state, payload) {
            state.permissoes = payload
        },
        setLogged(state, payload) {
            state.logged = payload
        },
        setUser(state, payload) {
            state.user = payload
            state.logged = true
        },
        setToken(state, payload) {
            state.logged = true
            state.token = payload
            axios.defaults.headers.common['Authorization'] = `${payload}`
            localStorage.setItem('IMPLANTACAO_TOKEN', payload)
            let data = payload.split('.')
            state.user = JSON.parse(atob(data[1]))
        },
        destroyUser(state, payload) {
            state.logged = false
            if (payload)
                state.token = null
            state.user = {}
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem('IMPLANTACAO_TOKEN')
            router.push('/login')
        }
    },
    actions: {
        logOff(context) {
            this.state.logged = false
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem('IMPLANTACAO_TOKEN')
            context.commit('setLogged', false)
            context.commit('destroyUser', null)
        },
        setLogged(context, payload) {
            context.commit('setLogged', payload)
        },
    },
    getters: {
        getUsuario(state) {
            return state.user
        },
        getToken(state) {
            return state.token
        },
        getLogged(state) {
            return state.logged
        },
        getDefaultRoute(state) {
            return state.user.route
        },
    },
    modules: {
        snackbar,
        grupo,
        menu,
        clientes,
        moradores,
        usuario,
        utils,
    }
})