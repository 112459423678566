<template>
  <v-navigation-drawer
    color="primary"
    app
    class="d-print-none menu-lateral"
    v-if="getLogged"
    v-model="showMenu"
    expand-on-hover
    rail
  >
    <v-list nav dense class="over">
      <v-list-item
        link
        :to="menuItem.to"
        v-for="menuItem in getMenu"
        :key="menuItem.name"
        :prepend-icon="menuItem.icon"
      >
        <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
      </v-list-item>
      <v-spacer></v-spacer>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "MenuComponent",
  data: () => {
    return {
      // showMenu: false,
      menuItems: [],
    };
  },
  // methods: {},
  computed: {
    ...mapState(["user"]),
    ...mapGetters([
      "getPermission",
      "getUsuario",
      "getLogged",
      "getMenu",
    ]),
    showMenu: {
      get() {
        return this.$store.getters.getShowMenu;
      },
      set(value) {
        this.$store.dispatch("setShowMenu", value);
      },
    },
  },
  watch: {},
};
</script>

<style scoped>
/* .over {
  overflow: auto;
  height: 100vh;
}
* {
  scrollbar-width: thin;
  scrollbar-color: gray rgb(82, 82, 82);
}
*::-webkit-scrollbar {
  width: 2px;
}
*::-webkit-scrollbar-track {
  background: rgb(161, 161, 161);
}
*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid rgb(82, 82, 82);
} */
/* .menu-lateral {
  overflow-x: hidden !important;
} */
</style>