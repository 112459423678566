<template>
  <v-app>
    <Menu v-if="showMenu"></Menu>
    <AppBar />
    <v-main>
      <v-container fluid>
        <router-view></router-view>
        <v-snackbar v-model="snck.snackbar" :color="snck.color" :timeout="snck.timeout">
          {{ snck.text }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Menu from "./components/Menu";
import AppBar from "./components/AppBar";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    AppBar,
    Menu,
    
  },
  computed: { ...mapGetters(["snackbar"]), ...mapGetters(["getLogged", "getUsuario"]) },
  watch: {
    snackbar() {
      this.snck = this.snackbar;
    },
    getUsuario(val) {
      if(val.level === 1) {
        this.showMenu = true;
        this.$store.dispatch('loadGrupos')
      } else {
        this.showMenu = false;
      }
    },
  },
  data: () => ({
    snck: {},
    title: "Implantação",
    showMenu: false,
  }),
  methods: {
  },
  created() {
    document.title = this.title;
    let token = localStorage.getItem("IMPLANTACAO_TOKEN");
    if (token != "undefined" && token != "null") {
      this.$store.commit("setToken", token);
      this.$store.commit("setLogged", true);
      // this.$router.push({ path: "/adm" });
      this.$store.dispatch("loadMenu", { cb: false})
      // this.getUser();
    } else {
      if (!this.$route.path === "/login") {
        this.$router.push({ path: "/login" });
      }
    }
  },
};
</script>
<style>
.v-main,
.v-container {
  display: flex;
  flex-direction: column;
}
.v-main {
  min-height: calc(100vh - 64px);
}
.v-container {
  flex-grow: 1;
}
.menu {
  background: #005f36 !important;
  color: rgb(223, 223, 223) !important;
}
.titleCard {
  background: #005f36 !important;
  color: rgb(223, 223, 223) !important;
}
.v-field {
  background-color: #FFF !important;
}
.primary-card {
  background-color: #F5F5F5 !important;
}
.secondary-card {
  background-color: #EEEEEE !important;
}
.primary-card .v-card-title,
.secondary-card .v-card-title,
.third-card .v-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF !important;
  background-color: rgb(var(--v-theme-primary), 0.75);
}

.v-table,
.v-table th {
  background-color: transparent !important;
}

.floating-buttom {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

</style>