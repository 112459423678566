import axios from 'axios'
import router from '@/router'

export default {
  state: {

  },
  mutations: {

  },
  actions: {
    editUser(context, payload) {
      axios.put(`user/${payload.id}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }).then((resp) => {
        if (resp.status < 400) {
          router.push('/clientes')
          axios(`user/${payload.id}`).then((resp) => {
            context.commit('setUser', resp.data)
          })
        }
      })
    }
  },
  getters: {

  },
}